import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Layout from "../components/layout";
import Blog from "../components/blogs/post-link"
import styles from "../scss/layouts/blog_index.module.scss"
import headerStyles from "../scss/modules/text_header.module.scss"
import BlogCta from "../components/cta/cta_blog";

export default ({ data }) => {
  const pageData = data.markdownRemark;
  const blogs = data.allMarkdownRemark;

  return (
    <Layout>
      <SEO title={ pageData.frontmatter.title } />

        <div className={headerStyles.text_header__header_grey}>
          <div className={headerStyles.text_header__header_inner}>
            <h1>{ pageData.frontmatter.title }</h1>
            <div dangerouslySetInnerHTML={{ __html: pageData.html }} />
          </div>
        </div>

      <div className={styles.blog_index}>
        <div className={styles.blog_index__inner}>
          { blogs.nodes.map(function(blog, index) {
            return <Blog key={ index }
                          title={ blog.frontmatter.title }
                          date={blog.frontmatter.publish_date}
                          excerpt={ blog.frontmatter.longExcerpt }
                          author={ blog.frontmatter.author }
                          image={ blog.frontmatter.avatar }
                          url={ blog.fields.slug}/>;
          }) }
        </div>
      </div>
      <BlogCta />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        template
      }
      html
      fields {
        slug
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/\\/news\\/[a-z0-9\\\\-]+.md$/"}
      }
      sort: {
        fields: frontmatter___publish_date,
        order: DESC
      })
       {
      nodes {
        fields {
          slug
        }
        html
        frontmatter {
          title
          publish_date(formatString: "MMMM Do, YYYY")
          longExcerpt
          author
          avatar {
            childImageSharp {
              fluid(maxWidth: 80, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
