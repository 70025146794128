import React from "react";
import styles from "../../scss/modules/cta_block.module.scss";
import cta from "../../scss/elements/buttons.module.scss";
import image from "../../images/cta_blog_index@2x.jpg";
import { Link } from "gatsby"

export default () => (
    <div className={[styles.cta, styles.cta__pink_bg].join(' ')}>
        <div className={styles.cta__inner}>
            <div className={styles.cta__content}>
                <h2>Interested in our news articles?</h2>
                <p>Get in touch today to see how we can help you with your planning project</p>
                <Link className={cta.arrow_button} to="/contact">Get in touch</Link>
            </div>
            <div className={styles.cta__image}>
                <img src={image} alt={"Get in touch with Northern Planners"}></img>
            </div>
        </div>
    </div>
  )
  