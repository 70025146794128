import React from "react"
import Img from "gatsby-image"
import styles from "../../scss/modules/blog_link.module.scss"
import { Link } from "gatsby"
import cta from "../../scss/elements/buttons.module.scss";

export default (post) => {

  return (

    <article className={styles.blog_link} itemProp="blogPost" itemScope itemType="https://schema.org/BlogPosting">
        <time className={styles.blog_link__date} dateTime={post.date} itemProp="datePublished"><span>{post.date}</span></time>
        <h2 className={styles.blog_link__title}itemProp="name headline"><a href={post.url}>{post.title}</a></h2>
        <p className={styles.blog_link__excerpt}>{ post.excerpt }</p>
        <div className={styles.blog_link__bio}>
          <div className={styles.blog_link__author}>
            <Img className={styles.blog_link__avatar}
                  fluid={ post.image.childImageSharp.fluid }
                  alt={"By "+post.author}
            />
            <span itemProp="author">{"By "+post.author}</span>
          </div>
          <div className={styles.blog_link__button}>
            <Link className={cta.button_post} to={post.url} alt={"View '"+post.title}>Read More</Link>
          </div>
        </div>
    </article>
  )
}
